body {
  background-color: #f2f6fc !important;
  margin: 0 !important;
  padding: 8px !important;
}

.MuiCard-root {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
  border-radius: 14px !important;
}

.simple-card .MuiCard-root {
  border-radius: 5px !important;
}

.MuiDataGrid-root {
  border: none !important;
}

pre {
  overflow: auto;
}

.ReactCrop {
  width: 100% !important;
}

.ReactCrop__child-wrapper {
  width: 100% !important;
}

.draggable-container {
  cursor: move;
  position: absolute;
  left: 0;
  top: 0;
}

.draggable-parent:hover .draggable-container {
  outline: 2px dashed black;
}

.draggable-parent:hover > img {
  opacity: 0.7;
}

.toolkit {
  box-sizing: border-box;
  margin: 0 50px;
  background-color: #eee;
  min-height: 65vh;
  border-radius: 15px;
}

/* Media qery for mobile devices */
@media (max-width: 768px) {
  .toolkit {
    margin: 0;
    padding: 10px;
  }
}
